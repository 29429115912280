import Error from "../../../util/Error";

const UNLOAD_ORDER_ATTACHMENT_STATE = {
    token: null,
    id: null,
    document: null,
    title: null,
    error: false
};

const ORDER_DAMAGE_ATTACHMENT_STATE = {
    token: null,
    id: null,
    document: null,
    title: null,
    error: false
};

const DEFAULT_INBOUND_ORDER_STATE = {
    document: {...UNLOAD_ORDER_ATTACHMENT_STATE},
    documents: [],
}

const DEFAULT_FORM_STATE = {
    orders: [], // DEFAULT_INBOUND_ORDER_STATE
}

const FORM_STATE = {
    id: null,
    orders: [],
    _method: 'post',
}

export default {
    data() {
        return {
            formFields: {...FORM_STATE},
            selectedLoad: null,
            unloadOrderAttachmentFormState: {...DEFAULT_FORM_STATE},
        }
    },
    methods: {
        handleUnloadClick(record) {
            const self = this;
            this.formFields.id = record.id
            this.formFields.orders = record.inbound_load_orders.map((item) => {
                return {
                    id: item.id,
                    order_id: item.order.id,
                    status: 8,
                    etr: null,
                    controller_name: null,
                    comments: null,
                    order: item.order,
                    documents: [],
                    document: {...UNLOAD_ORDER_ATTACHMENT_STATE},
                    damage_documents: [],
                    damage_document: {...ORDER_DAMAGE_ATTACHMENT_STATE},
                }
            });

            this.selectedLoad = record
        },
        handleAddUpdateUnloadAttachmentClick(groupIndex) {
            this.formFields.orders[groupIndex].document.error = false;
            this.formErrors = new Error({})
            let errors = {};

            if (!this.formFields.orders[groupIndex].document.document || this.formFields.orders[groupIndex].document.document.length <= 0 || !this.formFields.orders[groupIndex].document.document.id) {
                this.formFields.orders[groupIndex].document.error = true;
                errors.document = [this.$t('validation.required')];
            }

            if (!this.formFields.orders[groupIndex].document.title || _.trim(this.formFields.orders[groupIndex].document.title).length <= 2) {
                this.formFields.orders[groupIndex].document.error = true;
                errors.title = [this.$t('validation.required')];
            }

            _.map(this.formFields.orders[groupIndex].documents, (document) => {
                if (document.token !== this.formFields.orders[groupIndex].document.token) {
                    if (document.title === this.formFields.orders[groupIndex].document.title) {
                        errors.title = [this.$t('validation.duplicate')];
                        this.formFields.orders[groupIndex].document.error = true;
                        this.formErrors = new Error(errors);
                        return false;
                    }
                }
            });

            this.formErrors = new Error(errors)
            if (this.formFields.orders[groupIndex].document.error) return false;

            const entity = this.formFields.orders[groupIndex].document;
            let index = -1;
            if (entity.token)
                index = _.findIndex(this.formFields.orders[groupIndex].documents, {token: entity.token});

            if (this.formFields.orders[groupIndex].documents[index] !== undefined) {
                this.formFields.orders[groupIndex].documents[index] = entity;
            } else {
                entity.token = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
                this.formFields.orders[groupIndex].documents.push(entity)
            }

            this.formFields.orders[groupIndex].document = {
                ...UNLOAD_ORDER_ATTACHMENT_STATE,
                token: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
            }
        },
        handleDeleteUnLoadAttachmentClick(groupIndex, token) {
            const index = _.findIndex(this.formFields.orders[groupIndex].documents, {token: token})
            if (this.formFields.orders[groupIndex].documents[index] !== undefined) {
                this.formFields.orders[groupIndex].documents.splice(index, 1);
            }
        },
        handleEditUndLoadAttachmentClick(groupIndex, token) {
            const index = _.findIndex(this.formFields.orders[groupIndex].documents, {token: token})
            if (this.formFields.orders[groupIndex].documents[index] !== undefined) {
                this.formFields.orders[groupIndex].document = {...this.formFields.orders[groupIndex].documents[index]};
            }
        },
        resetUnLoadAttachment(groupIndex) {
            this.formFields.orders[groupIndex].document = {...UNLOAD_ORDER_ATTACHMENT_STATE}
            this.formFields.orders[groupIndex].document.error = false;
        },
        handleAddUpdateOrderDamageAttachmentClick(groupIndex) {
            this.formFields.orders[groupIndex].damage_document.error = false;
            this.formErrors = new Error({})
            let errors = {};

            if (!this.formFields.orders[groupIndex].damage_document.document || this.formFields.orders[groupIndex].damage_document.document.length <= 0 || !this.formFields.orders[groupIndex].damage_document.document.id) {
                this.formFields.orders[groupIndex].damage_document.error = true;
                errors.damage_document_err = [this.$t('validation.required')];
            }

            if (!this.formFields.orders[groupIndex].damage_document.title || _.trim(this.formFields.orders[groupIndex].damage_document.title).length <= 2) {
                this.formFields.orders[groupIndex].damage_document.error = true;
                errors.damage_title_err = [this.$t('validation.required')];
            }

            _.map(this.formFields.orders[groupIndex].damage_documents, (document) => {
                if (document.token !== this.formFields.orders[groupIndex].damage_document.token) {
                    if (document.title === this.formFields.orders[groupIndex].damage_document.title) {
                        errors.title = [this.$t('validation.duplicate')];
                        this.formFields.orders[groupIndex].damage_document.error = true;
                        this.formErrors = new Error(errors);
                        return false;
                    }
                }
            });

            this.formErrors = new Error(errors)
            if (this.formFields.orders[groupIndex].damage_document.error) return false;

            const entity = this.formFields.orders[groupIndex].damage_document;
            let index = -1;
            if (entity.token)
                index = _.findIndex(this.formFields.orders[groupIndex].damage_documents, {token: entity.token});

            if (this.formFields.orders[groupIndex].damage_documents[index] !== undefined) {
                this.formFields.orders[groupIndex].damage_documents[index] = entity;
            } else {
                entity.token = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
                this.formFields.orders[groupIndex].damage_documents.push(entity)
            }

            this.formFields.orders[groupIndex].damage_document = {
                ...ORDER_DAMAGE_ATTACHMENT_STATE,
                token: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
            }
        },
        handleDeleteOrderDamageAttachmentClick(groupIndex, token) {
            const index = _.findIndex(this.formFields.orders[groupIndex].damage_documents, {token: token})
            if (this.formFields.orders[groupIndex].damage_documents[index] !== undefined) {
                this.formFields.orders[groupIndex].damage_documents.splice(index, 1);
            }
        },
        handleEditOrderDamageAttachmentClick(groupIndex, token) {
            const index = _.findIndex(this.formFields.orders[groupIndex].damage_documents, {token: token})
            if (this.formFields.orders[groupIndex].damage_documents[index] !== undefined) {
                this.formFields.orders[groupIndex].damage_document = {...this.formFields.orders[groupIndex].damage_documents[index]};
            }
        },
        resetOrderDamageAttachment(groupIndex) {
            this.formFields.orders[groupIndex].damage_document = {...ORDER_DAMAGE_ATTACHMENT_STATE}
            this.formFields.orders[groupIndex].damage_document.error = false;
        },
    },
    computed: {
        title() {
            return this.$t('title.gateInConfirmation')
        }
    },
}
